import { useSpring } from 'react-spring';

export const useFade = transitionStatus =>
  useSpring({
    opacity:
      transitionStatus === 'entering' ||
      transitionStatus === 'entered' ||
      transitionStatus === 'POP'
        ? 1
        : 0,
  });
